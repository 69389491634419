import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { RootState } from "../rootReducer";
import {
  fetchTasksList,
  insertTask,
  deleteTaskById,
} from "../slices/tasksSlice";
import {
  Label,
  Message,
  Table,
  Container,
  Accordion,
  Loader,
  Transition,
  Header,
  Icon,
  Menu,
  Segment,
  Button,
} from "semantic-ui-react";

import {
  TaskTypeColors as ttc,
  TaskStatusColors as tsc,
} from "../tools/labelColors";

import moment from "moment";
import { default as AnsiUp } from "ansi_up";

export const OptimizationPage = () => {
  const dispatch = useDispatch();
  const ansi_up = new AnsiUp();
  const userProfile = useContext(AuthContext);

  const [activeIndex, setActiveIndex] = useState(0);

  const { isLoading, isInitialized, isSubmitting, isEmpty } = useSelector(
    (state: RootState) => state.tasks
  );

  const tasks = useSelector((state: RootState) =>
    Object.values(state.tasks.tasksById)
      .filter((task) => task.taskType !== "BACKTEST")
      .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
  );

  useEffect(() => {
    dispatch(fetchTasksList());
  }, [dispatch]);

  const handleRunNow = (taskType: string) => {
    const task = {
      status: "SUBMITTED",
      taskType: taskType,
    };
    dispatch(insertTask(task));
  };

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(index);
  };

  const handleDelete = (taskId: string) => {
    dispatch(deleteTaskById(taskId));
  };

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-optimization" }}
        title={`Optimization | Levitas Capital`}
      />
      <Container>
        <Menu inverted borderless>
          <Menu.Item>
            <Header as={"h1"} content="History" inverted />
          </Menu.Item>
          {isInitialized && !isEmpty && userProfile.username === "ahernandez" && (
            <Menu.Menu position="right">
              <Menu.Item>
                <Button.Group compact>
                  <Button
                    inverted
                    color={ttc("FORWARDTEST")}
                    icon="play"
                    onClick={() => handleRunNow("FORWARDTEST")}
                    content="Fw T"
                  />
                  <Button
                    inverted
                    color={ttc("OPTIMIZE")}
                    icon="play"
                    onClick={() => handleRunNow("OPTIMIZE")}
                    content="Op"
                  />
                  <Button
                    inverted
                    color={ttc("CALCULATE")}
                    icon="play"
                    onClick={() => handleRunNow("CALCULATE")}
                    content="Calc"
                  />
                </Button.Group>
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>

        {(!isInitialized || isEmpty) && (
          <Segment placeholder padded>
            {isInitialized ? (
              <>
                <Header icon>
                  <Icon name="expand" />
                  History is empty
                </Header>
                {userProfile.username === "ahernandez" && (
                  <Button.Group>
                    <Button
                      color={ttc("OPTIMIZE")}
                      icon="play"
                      onClick={() => handleRunNow("OPTIMIZE")}
                      content="Optimize"
                    />
                    <Button.Or />
                    <Button
                      color={ttc("CALCULATE")}
                      icon="play"
                      onClick={() => handleRunNow("CALCULATE")}
                      content="Calculate"
                    />
                  </Button.Group>
                )}
              </>
            ) : (
              <Loader active inline="centered" size="massive" />
            )}
          </Segment>
        )}

        {isInitialized && !isEmpty && (
          <Transition>
            <Accordion fluid styled>
              {tasks.map((task, index) => (
                <div key={index}>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={handleClick}
                  >
                    <Menu borderless>
                      <Menu.Item fitted>
                        <Icon name="dropdown" />
                        <Label
                          color={ttc(task.taskType)}
                          horizontal
                          image
                          {...(activeIndex === index ? { size: "large" } : {})}
                        >
                          {task.taskType}
                          {activeIndex !== index && (
                            <Label.Detail>
                              {moment(task.updatedAt).format("L")}
                            </Label.Detail>
                          )}
                        </Label>
                      </Menu.Item>
                      {activeIndex !== index && (
                        <Menu.Menu position="right">
                          <Menu.Item fitted="vertically">
                            <Label color={tsc(task.status)}>
                              {task.status === "RUNNING" && (
                                <Icon loading name="spinner" />
                              )}
                              {task.status}
                            </Label>
                          </Menu.Item>
                        </Menu.Menu>
                      )}
                    </Menu>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    {activeIndex === index && task.status === "COMPLETE" && (
                      <Header as={"h3"} floated="right">
                        <Link to={`/optimization/${task.id}`}>
                          Details
                          <Icon name="arrow right" />
                        </Link>
                      </Header>
                    )}
                    <Table basic="very" compact size="small" fixed>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell width={2}>ID</Table.Cell>
                          <Table.Cell>{task.id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={2}>Status</Table.Cell>
                          <Table.Cell>
                            <Label color={tsc(task.status)}>
                              {task.status === "RUNNING" && (
                                <Icon loading name="spinner" />
                              )}
                              {task.status}
                            </Label>
                            {task.process_log && (
                              <Transition>
                                <Message color="black" className="process-log">
                                  {"> "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: ansi_up.ansi_to_html(
                                        task.process_log
                                      ),
                                    }}
                                  />
                                </Message>
                              </Transition>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={2}>Created At</Table.Cell>
                          <Table.Cell>
                            {moment(task.createdAt).format("LTS L")} (
                            {moment(task.createdAt).fromNow()})
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={2}>Updated</Table.Cell>
                          <Table.Cell>
                            {moment(task.updatedAt).format("LTS L")} (
                            {moment(task.updatedAt).fromNow()})
                          </Table.Cell>
                        </Table.Row>
                        {task.results && task.results.historical_data && (
                          <Table.Row>
                            <Table.Cell width={2}>Data Range</Table.Cell>
                            <Table.Cell>
                              {task.results.historical_data}
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                    <Button
                      content="delete"
                      size="mini"
                      basic
                      color="red"
                      compact
                      loading={isSubmitting}
                      onClick={() => handleDelete(task.id)}
                    />
                  </Accordion.Content>
                </div>
              ))}
            </Accordion>
          </Transition>
        )}
      </Container>
    </>
  );
};
