import axios from "axios";
import * as parseCookie from "./parseCookie";
const baseUrl = process.env.API_URL;

export interface Task {
  id: string;
  taskType: string;
  status: string;
  parameters?: any;
  results?: any;
  visualization?: any;
  createdAt: string;
  updatedAt: string;

  process_log?: string;
}

export async function getTasks(forwardFilter = false): Promise<Task[]> {
  const token = parseCookie.getToken("__session");
  let url = `${baseUrl}/tasks?filter[order][]=createdAt%20DESC&filter[limit]=40`;
  if (forwardFilter) {
    url = `${baseUrl}/tasks?filter[where][taskType]=FORWARDTEST&filter[limit]=200`;
  }

  const { data } = await axios.get<Task[]>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function getTaskById(taskId: string) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tasks/${taskId}`;

  const { data } = await axios.get<Task>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function createTask(task: any) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tasks/`;

  const { data } = await axios.post<any>(url, task, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function updateTask(task: Task) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tasks/${task.id}`;

  const { data } = await axios.put<Task>(url, task, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function deleteTask(taskId: string) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tasks/${taskId}`;

  const { data } = await axios.delete<void>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
