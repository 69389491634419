import React, { useEffect, useRef, useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import { RootState } from "../rootReducer";
import {
  fetchTestsList,
  insertTest,
  deleteTestById,
} from "../slices/testsSlice";

import { Formik } from "formik";
import { FormField } from "../components/FormField";
import * as Yup from "yup";
import Log from "../tools/Log";

import {
  Modal,
  Divider,
  Form,
  Label,
  Message,
  Table,
  Container,
  Accordion,
  Loader,
  Transition,
  Header,
  Icon,
  Menu,
  Segment,
  Button,
} from "semantic-ui-react";

import {
  TaskTypeColors as ttc,
  TaskStatusColors as tsc,
} from "../tools/labelColors";

import moment from "moment";
import { default as AnsiUp } from "ansi_up";

export const BacktestPage = () => {
  const dispatch = useDispatch();
  const ansi_up = new AnsiUp();
  const userProfile = useContext(AuthContext);
  const formikRef: any = useRef();

  const [showModal, setShowModal] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const { isLoading, isInitialized, isSubmitting } = useSelector(
    (state: RootState) => state.tests
  );

  const tests = useSelector((state: RootState) =>
    Object.values(state.tests.testsById).sort(
      (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix()
    )
  );

  useEffect(() => {
    dispatch(fetchTestsList());
  }, [dispatch]);

  async function onSubmit(data) {
    Log.debug("data", data);
    const task = {
      status: "SUBMITTED",
      synchronize: data.synchronize,
      configuration: data,
      testType: "BACKTEST",
    };
    await dispatch(insertTest(task));
    setShowModal(false);
  }

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    setActiveIndex(index);
  };

  const handleDelete = (testId: string) => {
    dispatch(deleteTestById(testId));
  };

  const validation = Yup.object().shape({
    //id: Yup.string().label("Username").trim().required(),
    //password: Yup.string().label("Password").trim().required(),
    ////      .matches(
    ////        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    ////        "Minimum eight characters, at least one letter, one number and one special character"
    ////      ),
    //token: Yup.string().label("Registration Token").max(64).trim().required(),
  });

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-optimization" }}
        title={`Backtesting | Levitas Capital`}
      />
      <Container>
        <Menu inverted borderless>
          <Menu.Item>
            <Header as={"h1"} content="Backtests" inverted />
          </Menu.Item>
          {isInitialized && tests.length > 0 && (
            <Menu.Menu position="right">
              <Menu.Item>
                <Button
                  compact
                  inverted
                  color={ttc("BACKTEST")}
                  icon="plus"
                  content="Create New"
                  onClick={() => setShowModal(true)}
                />
              </Menu.Item>
            </Menu.Menu>
          )}
        </Menu>

        {(!isInitialized || tests.length === 0) && (
          <Segment placeholder padded>
            {isInitialized ? (
              <>
                <Header icon>
                  <Icon name="expand" />
                  History is empty
                </Header>
                <Button
                  color={ttc("BACKTEST")}
                  icon="plus"
                  content="Create New Backtest"
                  onClick={() => setShowModal(true)}
                />
              </>
            ) : (
              <Loader active inline="centered" size="massive" />
            )}
          </Segment>
        )}

        {isInitialized && tests.length > 0 && (
          <Transition>
            <Accordion fluid styled>
              {tests.map((test, index) => (
                <div key={index}>
                  <Accordion.Title
                    active={activeIndex === index}
                    index={index}
                    onClick={handleClick}
                  >
                    <Menu borderless>
                      <Menu.Item fitted>
                        <Icon name="dropdown" />
                        <Label
                          color={ttc(test.testType)}
                          horizontal
                          image
                          {...(activeIndex === index ? { size: "large" } : {})}
                        >
                          {test.testType}
                          {activeIndex !== index && (
                            <Label.Detail>
                              {moment(test.updatedAt).format("L")}
                            </Label.Detail>
                          )}
                        </Label>
                      </Menu.Item>
                      {activeIndex !== index && (
                        <Menu.Menu position="right">
                          <Menu.Item fitted="vertically">
                            <Label color={tsc(test.status)}>
                              {test.status === "RUNNING" && (
                                <Icon loading name="spinner" />
                              )}
                              {test.status}
                            </Label>
                            {test.synchronize && (
                              <Label color="yellow" content="SYNC" />
                            )}
                          </Menu.Item>
                        </Menu.Menu>
                      )}
                    </Menu>
                  </Accordion.Title>
                  <Accordion.Content active={activeIndex === index}>
                    {activeIndex === index && (
                      <Header as={"h3"} floated="right">
                        <Link to={`/backtests/${test.id}`}>
                          Details
                          <Icon name="arrow right" />
                        </Link>
                      </Header>
                    )}
                    <Table basic="very" compact size="small" fixed>
                      <Table.Body>
                        <Table.Row>
                          <Table.Cell width={2}>ID</Table.Cell>
                          <Table.Cell>{test.id}</Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={2}>Status</Table.Cell>
                          <Table.Cell>
                            <Label color={tsc(test.status)}>
                              {test.status === "RUNNING" && (
                                <Icon loading name="spinner" />
                              )}
                              {test.status}
                            </Label>
                            {test.synchronize && (
                              <Label color="yellow" content="SYNC" />
                            )}
                            {test.process_log && (
                              <Transition>
                                <Message color="black" className="process-log">
                                  {"> "}
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: ansi_up.ansi_to_html(
                                        test.process_log
                                      ),
                                    }}
                                  />
                                </Message>
                              </Transition>
                            )}
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={2}>Created At</Table.Cell>
                          <Table.Cell>
                            {moment(test.createdAt).format("LTS L")} (
                            {moment(test.createdAt).fromNow()})
                          </Table.Cell>
                        </Table.Row>
                        <Table.Row>
                          <Table.Cell width={2}>Updated</Table.Cell>
                          <Table.Cell>
                            {moment(test.updatedAt).format("LTS L")} (
                            {moment(test.updatedAt).fromNow()})
                          </Table.Cell>
                        </Table.Row>
                        {test.results && test.results.historical_data && (
                          <Table.Row>
                            <Table.Cell width={2}>Data Range</Table.Cell>
                            <Table.Cell>
                              {test.results.historical_data}
                            </Table.Cell>
                          </Table.Row>
                        )}
                      </Table.Body>
                    </Table>
                    <Button
                      content="delete"
                      size="mini"
                      basic
                      color="red"
                      compact
                      loading={isSubmitting}
                      onClick={() => handleDelete(test.id)}
                    />
                  </Accordion.Content>
                </div>
              ))}
            </Accordion>
          </Transition>
        )}
        <Modal
          size="tiny"
          closeIcon
          open={showModal}
          onClose={() => setShowModal(false)}
        >
          <Header icon="plus" content="Create New Backtest" />
          <Modal.Content>
            <Formik
              innerRef={formikRef}
              initialValues={{
                startDate: "2014-01-02",
                ddRestriction: 30,
                ddDuration: 80,
                ddSingleLimit: 10,
                optGenerations: 1,
                synchronize: false,
              }}
              validationSchema={validation}
              onSubmit={onSubmit}
            >
              {(props) => (
                <Form onSubmit={props.handleSubmit} autoComplete="off">
                  <FormField
                    id="startDate"
                    name="startDate"
                    component={Form.Input}
                    type="date"
                    label="Start Date"
                    min="2007-01-03"
                  />
                  <FormField
                    id="ddRestriction"
                    name="ddRestriction"
                    component={Form.Input}
                    type="number"
                    label="Drawdown Limit (%)"
                    placeholder="30"
                    icon="percent"
                  />
                  <FormField
                    id="ddDuration"
                    name="ddDuration"
                    component={Form.Input}
                    type="number"
                    label="Drawdown Duration Limit (days)"
                    placeholder="80"
                  />
                  <FormField
                    id="ddSingleLimit"
                    name="ddSingleLimit"
                    component={Form.Input}
                    type="number"
                    label="Single Day Drawdown Limit (%)"
                    placeholder="10"
                    icon="percent"
                  />
                  <Divider />
                  <FormField
                    id="synchronize"
                    name="synchronize"
                    component={Form.Checkbox}
                    label="Synchronize"
                  />
                  <FormField
                    id="optGenerations"
                    name="optGenerations"
                    component={Form.Input}
                    type="number"
                    label="Optimization Generations"
                    placeholder="1"
                  />
                </Form>
              )}
            </Formik>
          </Modal.Content>
          <Modal.Actions>
            <Button content="Cancel" onClick={() => setShowModal(false)} />
            <Button
              color={ttc("BACKTEST")}
              icon="play"
              content="Create"
              loading={isSubmitting}
              onClick={() => formikRef.current.submitForm()}
            />
          </Modal.Actions>
        </Modal>
      </Container>
    </>
  );
};
