import axios from "axios";

export interface StatusCheckTarget {
  name: string;
  url: string;
  type: "api" | "worker";
}

export async function statusCheck(target: StatusCheckTarget) {
  const { data } = await axios.get(target.url, {
    headers: {},
  });

  return data;
}
