import React from "react";

import * as Yup from "yup";

import { Form, Button, Header } from "semantic-ui-react";
import { Formik } from "formik";
import { FormField } from "../FormField";

interface Props {
  title: string;
  onSubmit: (any) => void;
  submitting: boolean;
}

export default function ForgotCredentialsForm({
  title = "Forgot Password",
  onSubmit,
  submitting = false,
}: Props) {
  const validation = Yup.object().shape({
    email: Yup.string().label("Email").trim().email().required(),
  });

  return (
    <>
      <Header as="h1" inverted textAlign="center">
        {title}
      </Header>
      <Formik
        initialValues={{}}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FormField
              name="email"
              component={Form.Input}
              label="Email"
              placeholder="Email Address"
            />
            <Button secondary type="submit" loading={submitting} fluid>
              SEND LINK
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}
