import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { RootState } from "../rootReducer";
import { fetchTaskById } from "../slices/tasksSlice";
import {
  Label,
  Divider,
  List,
  Container,
  Loader,
  Header,
  Icon,
  Segment,
  Tab,
  Breadcrumb,
} from "semantic-ui-react";

import {
  TaskTypeColors as ttc,
  TaskStatusColors as tsc,
} from "../tools/labelColors";

import { ResultsTab } from "../components/tasks/ResultsTab";
import { RawTab } from "../components/tasks/RawTab";
import { VisualizationTab } from "../components/tasks/VisualizationTab";

import moment from "moment";

export const OptimizationDetailsPage = () => {
  const dispatch = useDispatch();
  const { taskId } = useParams();

  const { isLoading, isInitialized } = useSelector(
    (state: RootState) => state.tasks
  );

  const task = useSelector((state: RootState) => state.tasks.tasksById[taskId]);

  useEffect(() => {
    if (!task && taskId) dispatch(fetchTaskById(taskId));
  }, [dispatch, taskId, task]);

  const panes = [
    {
      menuItem: "Results",
      render: () => (
        <Tab.Pane basic attached={false} loading={isLoading}>
          <ResultsTab task={task} />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Raw Data",
      render: () => (
        <Tab.Pane basic attached={false} loading={isLoading}>
          <RawTab task={task} />
        </Tab.Pane>
      ),
    },
    ...(task?.visualization
      ? [
          {
            menuItem: "Visualization",
            render: () => (
              <Tab.Pane
                attached={false}
                loading={isLoading}
                style={{ height: "100vh" }}
              >
                <VisualizationTab task={task} />
              </Tab.Pane>
            ),
          },
        ]
      : []),
  ];

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-optimization-detail" }}
        title={`Optimization Detail | Levitas Capital`}
      />
      {!task ? (
        <Loader active inline="centered" size="massive" inverted />
      ) : (
        <Container>
          <Header as={"h1"} inverted>
            Task Details
            <Label horizontal color={tsc(task.status)}>
              {task.status === "RUNNING" && <Icon loading name="spinner" />}
              {task.status}
            </Label>
          </Header>
          <Breadcrumb>
            <Breadcrumb.Section link as={Link} to={"/optimization"}>
              Optimizations
            </Breadcrumb.Section>
            <Breadcrumb.Divider />
            <Breadcrumb.Section active>{taskId}</Breadcrumb.Section>
          </Breadcrumb>
          <Segment inverted size="mini">
            <List>
              <List.Item>
                <List.Icon name="chevron right" />
                <List.Content>
                  {"Created At: "}
                  {moment(task.createdAt).format("LTS L")} (
                  {moment(task.createdAt).fromNow()})
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Icon name="chevron right" />
                <List.Content>
                  {"Updated At: "}
                  {moment(task.updatedAt).format("LTS L")} (
                  {moment(task.updatedAt).fromNow()})
                </List.Content>
              </List.Item>
            </List>
          </Segment>

          <Divider />

          <Tab
            menu={{
              secondary: true,
              inverted: true,
            }}
            panes={panes}
          />
        </Container>
      )}
    </>
  );
};
