import React, { useState } from "react";

import { Item, Input, Divider, Table, Header } from "semantic-ui-react";

import { Task } from "../../api/tasksApi";

export const ResultsTab = ({ task }: { task: Task }) => {
  const [endingNav, setEndingNav] = useState(0);

  const allocCalc = (en, ca, a) => {
    const value = Math.floor((en / ca) * a);
    return value === 0 ? "" : value;
  };

  const renderBodyRow = ({ key, ...props }) => ({
    key,
    cells: Object.values(props),
  });

  return (
    <>
      <Item>
        <Header as={"h3"} content="Allocations" inverted />
      </Item>
      <Table celled compact unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ending Nav</Table.HeaderCell>
            <Table.HeaderCell>Strategies</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell>F2</Table.HeaderCell>
            <Table.HeaderCell>F3</Table.HeaderCell>
            <Table.HeaderCell>F6+F5</Table.HeaderCell>
            <Table.HeaderCell>ES</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <Input
                placeholder="Ending NAV"
                onChange={(event) => setEndingNav(Number(event.target.value))}
                type="number"
                fluid
              />
            </Table.Cell>
            <Table.Cell>{task.results.strategy_combo_key}</Table.Cell>
            <Table.Cell />
            <Table.Cell className="number">
              {allocCalc(
                endingNav,
                task.results.allocations[0],
                task.results.allocations[1]
              )}
            </Table.Cell>
            <Table.Cell className="number">
              {allocCalc(
                endingNav,
                task.results.allocations[0],
                task.results.allocations[2]
              )}
            </Table.Cell>
            <Table.Cell className="number">
              {allocCalc(
                endingNav,
                task.results.allocations[0],
                task.results.allocations[3]
              )}
            </Table.Cell>
            <Table.Cell className="number">
              {allocCalc(
                endingNav,
                task.results.allocations[0],
                task.results.allocations[4]
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row warning>
            <Table.Cell>Allocations:</Table.Cell>
            <Table.Cell className="number">
              {task.results.allocations[0]}
            </Table.Cell>
            <Table.Cell />
            <Table.Cell className="number">
              {task.results.allocations[1]}
            </Table.Cell>
            <Table.Cell className="number">
              {task.results.allocations[2]}
            </Table.Cell>
            <Table.Cell className="number">
              {task.results.allocations[3]}
            </Table.Cell>
            <Table.Cell className="number">
              {task.results.allocations[4]}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Divider />

      <Item>
        <Header as={"h3"} content="Strategies" inverted />
      </Item>
      <Table
        celled
        size="small"
        selectable
        striped
        compact="very"
        definition
        unstackable
        headerRow={task.parameters.param_inputs.header}
        renderBodyRow={renderBodyRow}
        tableData={task.parameters.param_inputs.data}
        style={{ overflowX: "scroll", display: "block" }}
      />
    </>
  );
};
