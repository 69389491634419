import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import _ from "lodash";

import { RootState } from "../rootReducer";
import { fetchAllocations, fetchAllocations2 } from "../slices/staticsSlice";
import { Table, Tab, Container, Loader, Header } from "semantic-ui-react";

export const AllocationsPage = () => {
  const dispatch = useDispatch();

  const { isLoading, allocations, allocations2 } = useSelector(
    (state: RootState) => state.statics
  );

  useEffect(() => {
    if (!allocations) dispatch(fetchAllocations());
    if (!allocations2) dispatch(fetchAllocations2());
  }, [dispatch, allocations, allocations2]);

  const renderBodyRow = ([...data], i) => ({
    key: data[0],
    cells: [
      { key: "strategy", content: data[0] },
      { key: "combo", content: data[1] },
      { key: "f2", content: data[2] },
      { key: "f3", content: data[3] },
      { key: "f6+f5", content: data[4] },
      { key: "es", content: data[5] },
    ],
  });

  const renderBodyRow2 = ([...data], i) => ({
    key: data[0],
    cells: [
      { key: "strategy", content: data[0] },
      { key: "j1", content: data[1] },
      { key: "jl", content: data[2] },
      { key: "rlaf2", content: data[3] },
      { key: "rla", content: data[4] },
      { key: "sps", content: data[5] },
      { key: "sps.b", content: data[6] },
      { key: "esl", content: data[7] },
      { key: "rf3", content: data[8] },
      { key: "r1", content: data[9] },
      { key: "rx", content: data[10] },
      { key: "rc factor adj", content: data[11] },
    ],
  });

  const panes = [
    {
      menuItem: "Combo Allocations Variable",
      render: () => (
        <Tab.Pane loading={isLoading}>
          <Table
            displayname="allocations"
            celled
            size="small"
            selectable
            striped
            compact="very"
            definition
            unstackable
            renderBodyRow={renderBodyRow2}
            headerRow={[
              "Strategy",
              "J1",
              "JL",
              "RlaF2",
              "RLA",
              "SPS",
              "SPS.b",
              "ESL",
              "RF3",
              "R1",
              "Rx",
              "RC Factor Adj",
            ]}
            tableData={_.map(allocations2, (v, UID) => [UID, ...v])}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Combos Net Allocation Positions",
      render: () => (
        <Tab.Pane loading={isLoading}>
          <Table
            displayname="allocations2"
            celled
            size="small"
            selectable
            striped
            compact="very"
            definition
            unstackable
            renderBodyRow={renderBodyRow}
            headerRow={["Strategy", "Combo", "F2", "F3", "F6+F5", "ES"]}
            tableData={_.map(allocations, (v, UID) => [UID, ...v])}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-allocations" }}
        title={`Allocations | Levitas Capital`}
      />
      {!allocations || isLoading ? (
        <Loader active inline="centered" size="massive" inverted />
      ) : (
        <Container>
          <Header as={"h1"} inverted>
            Allocations
          </Header>
          <Tab
            menu={{
              secondary: true,
              inverted: true,
            }}
            panes={panes}
          />
        </Container>
      )}
    </>
  );
};
