import React, { useState } from "react";

import * as Yup from "yup";

import { Form, Button, Header, Message, Transition } from "semantic-ui-react";
import { Formik } from "formik";
import { FormField } from "../FormField";

interface Props {
  onSubmit: (any) => void;
  submitting: boolean;
  credentialError: boolean;
}

export default function LoginForm({
  onSubmit,
  submitting = false,
  credentialError = false,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const validation = Yup.object().shape({
    username: Yup.string().label("Username").trim().required(),
    password: Yup.string().label("Password").trim().required(),
  });

  return (
    <>
      <Header as="h1" inverted textAlign="center">
        Login
      </Header>
      <Formik
        initialValues={{}}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <FormField
              id="username"
              name="username"
              component={Form.Input}
              autoComplete="username email"
              placeholder="Username"
            />
            <FormField
              id="password"
              name="password"
              component={Form.Input}
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              placeholder="Password"
              action={{
                icon: showPassword ? "eye slash" : "eye",
                onClick: () => setShowPassword(!showPassword),
                type: "button",
              }}
            />
            <Transition.Group animation={"fade"}>
              {credentialError && (
                <Message negative compact size="small">
                  The username or password you entered is incorrect
                </Message>
              )}
            </Transition.Group>
            <Button
              fluid
              primary
              type="submit"
              loading={submitting}
              content="Continue"
            />
          </Form>
        )}
      </Formik>
    </>
  );
}
