import axios from "axios";
import * as parseCookie from "./parseCookie";
const url = process.env.API_URL;

export const getToken = async (username, pwd) => {
  const authToken = await apiCallForToken(username, pwd);
  document.cookie = `__session=${authToken.data.token}`;
  return true;
};

export const apiCallForToken = (username, pwd) => {
  const credentials = { username: username, password: pwd };
  return axios.post(`${url}/token`, credentials);
};

export const resetToken = async () => {
  const token = parseCookie.getToken("__session");
  const authToken = await apiCallForresetToken(token);
  document.cookie = "__session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
  document.cookie = `__session=${authToken.data.access_token}`;
  return true;
};

export const apiCallForresetToken = (token) => {
  return axios.get(`${url}/oauth2/token/refresh`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
