import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Task,
  getTasks,
  getTaskById,
  createTask,
  updateTask,
  deleteTask,
} from "../api/tasksApi";

import { AppThunk } from "../store";

interface TaskState {
  tasksById: Record<string, Task>;
  isLoading: boolean;
  isSubmitting: boolean;
  isInitialized: boolean;
  isEmpty: boolean;
  error: {
    timestamp: number;
    data: string;
  } | null;
}

const initialState: TaskState = {
  tasksById: {},
  isLoading: false,
  isSubmitting: false,
  isInitialized: false,
  isEmpty: true,
  error: null,
};

function startLoading(state: TaskState) {
  state.isLoading = true;
  state.error = null;
}

function startSubmitting(state: TaskState) {
  state.isSubmitting = true;
  state.error = null;
}

function loadingFailed(state: TaskState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = { timestamp: new Date().getTime(), data: action.payload };
}

const tasks = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getTaskStart: startLoading,
    getTasksListStart: startLoading,
    createTaskStart: startSubmitting,
    updateTaskStart: startSubmitting,
    deleteTaskStart: startSubmitting,

    getTaskSuccess: (state, { payload }: PayloadAction<Task>) => {
      const { id } = payload;

      state.tasksById[id] = payload;
      state.isLoading = false;
      state.isEmpty = false;
      state.isInitialized = true;
      state.error = null;
    },
    getTasksListSuccess: (state, { payload }: PayloadAction<Task[]>) => {
      state.isLoading = false;
      state.error = null;
      state.isEmpty = false;
      state.isInitialized = true;

      payload.forEach((task) => {
        state.tasksById[task.id] = task;
      });
    },
    createTaskSuccess: (state, { payload }: PayloadAction<Task>) => {
      const { id } = payload;

      state.tasksById[id] = payload;
      state.isSubmitting = false;
      state.isInitialized = true;
      state.isEmpty = false;
      state.error = null;
    },
    updateTaskSuccess: (state, { payload }: PayloadAction<Task>) => {
      const { id } = payload;

      state.tasksById[id] = payload;
      state.isSubmitting = false;
      state.isInitialized = true;
      state.isEmpty = false;
      state.error = null;
    },
    updateTaskStatus: (state, { payload }: PayloadAction<any>) => {
      const { id, process_log } = payload;

      if (id in state.tasksById) {
        state.tasksById[id].process_log = process_log;
      }
    },

    deleteTaskSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isSubmitting = false;
      state.error = null;

      if (payload in state.tasksById) {
        delete state.tasksById[payload];
      }
    },

    getTasksListNoDataSuccess(state) {
      state.isEmpty = true;
      state.isLoading = false;
      state.isInitialized = true;
      state.error = null;
    },
    getTaskFailure: loadingFailed,
    getTasksListFailure: loadingFailed,
    createTaskFailure: loadingFailed,
    updateTaskFailure: loadingFailed,
    deleteTaskFailure: loadingFailed,
  },
});

export const {
  getTaskStart,
  getTasksListStart,
  getTaskSuccess,
  getTasksListSuccess,
  getTasksListNoDataSuccess,
  getTaskFailure,
  getTasksListFailure,

  createTaskStart,
  createTaskSuccess,
  createTaskFailure,

  updateTaskStart,
  updateTaskSuccess,
  updateTaskStatus,
  updateTaskFailure,

  deleteTaskStart,
  deleteTaskSuccess,
  deleteTaskFailure,
} = tasks.actions;

export default tasks.reducer;

export const fetchTasksList = (forwardFilter = false): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(getTasksListStart());
    const tasksList = await getTasks(forwardFilter);
    if (tasksList && tasksList.length) {
      dispatch(getTasksListSuccess(tasksList));
    } else {
      dispatch(getTasksListNoDataSuccess());
    }
  } catch (err) {
    dispatch(getTasksListFailure(err.toString()));
  }
};

export const fetchTaskById = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getTaskStart());
    const task = await getTaskById(id);
    dispatch(getTaskSuccess(task));
  } catch (err) {
    dispatch(getTaskFailure(err.toString()));
  }
};

export const insertTask = (task: any): AppThunk => async (dispatch) => {
  try {
    dispatch(createTaskStart());
    const response = await createTask(task);
    dispatch(createTaskSuccess(response));
  } catch (err) {
    dispatch(createTaskFailure(err.toString()));
  }
};

export const updateTaskById = (task: Task): AppThunk => async (dispatch) => {
  try {
    dispatch(updateTaskStart());
    const response = await updateTask(task);
    dispatch(updateTaskSuccess(response));
  } catch (err) {
    dispatch(updateTaskFailure(err.toString()));
  }
};

export const deleteTaskById = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteTaskStart());
    await deleteTask(id);
    dispatch(deleteTaskSuccess(id));
  } catch (err) {
    dispatch(deleteTaskFailure(err.toString()));
  }
};

export const updateTaskData = (update: Task): AppThunk => async (dispatch) => {
  try {
    dispatch(updateTaskSuccess(update));
  } catch (err) {
    dispatch(updateTaskFailure(err.toString()));
  }
};

export const updateTaskDataStatus = (update: any): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(updateTaskStatus(update));
  } catch (err) {
    dispatch(updateTaskFailure(err.toString()));
  }
};
