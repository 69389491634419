import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
//import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment";
import { RootState } from "../rootReducer";
import _ from "lodash";
import numeral from "numeral";

import { fetchTasksList, deleteTaskById } from "../slices/tasksSlice";

import {
  Menu,
  Header,
  Transition,
  Label,
  Icon,
  Input,
  Card,
  Container,
  Loader,
  Divider,
  Table,
} from "semantic-ui-react";

import {
  TaskTypeColors as ttc,
  TaskStatusColors as tsc,
} from "../tools/labelColors";

export const ForwardtestPage = () => {
  const dispatch = useDispatch();
  const [startingNav, setStartingNav] = useState(-1);

  const [calculatedTasks, setCalculatedTasks]: any = useState([]);

  const { isLoading, isInitialized, isSubmitting, isEmpty } = useSelector(
    (state: RootState) => state.tasks
  );

  const tasks = useSelector((state: RootState) =>
    Object.values(state.tasks.tasksById)
      .filter((task) => task.taskType === "FORWARDTEST")
      .sort((a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix())
  );

  useEffect(() => {
    dispatch(fetchTasksList(true));
  }, [dispatch]);

  useEffect(() => {
    let tmp = _.cloneDeep(tasks);
    let runningNav = startingNav;
    for (var i = tmp.length - 2; i >= 0; i--) {
      if (
        tmp[i+1]?.results?.strategy_combo_key
      ) {
        const ttl = Math.abs(tmp[i+1]?.results?.allocations?.[1])
                    + Math.abs(tmp[i+1]?.results?.allocations?.[2])
                    + Math.abs(tmp[i+1]?.results?.allocations?.[3])
                    + Math.abs(tmp[i+1]?.results?.allocations?.[4]);
        let pc = 0
        if (tmp[i+1]?.results?.allocations?.[1] !== 0){
          pc += ((tmp[i+1].results.allocations[1] / ttl) 
          * ((tmp[i].results.trading.f2 -
            tmp[i+1].results.trading.f2) /
          tmp[i+1].results.trading.f2));
        }
        if (tmp[i+1]?.results?.allocations?.[2] !== 0){
          pc += ((tmp[i+1].results.allocations[2] / ttl)  
          * ((tmp[i].results.trading.f3 -
            tmp[i+1].results.trading.f3) /
          tmp[i+1].results.trading.f3));
        }
        if (tmp[i+1]?.results?.allocations?.[3] !== 0){
          pc += ((tmp[i+1].results.allocations[3] / ttl)  
          * ((tmp[i].results.trading.f5 -
            tmp[i+1].results.trading.f5) /
          tmp[i+1].results.trading.f5));
        }
        if (tmp[i+1]?.results?.allocations?.[4] !== 0){
          pc += ((tmp[i+1].results.allocations[4] / ttl) 
          * ((tmp[i].results.trading.sp -
            tmp[i+1].results.trading.sp) /
          tmp[i+1].results.trading.sp));
        }
        tmp[i].results.trading.calc_percent_change = pc

        tmp[i].results.trading.calc_starting_nav = runningNav;
        tmp[i].results.trading.calc_ending_nav =
          runningNav + runningNav * tmp[i].results.trading.calc_percent_change;
        tmp[i].results.trading.calc_day_profit =
          tmp[i].results.trading.calc_ending_nav -
          tmp[i].results.trading.calc_starting_nav;

        runningNav = tmp[i].results.trading.calc_ending_nav;
      }
    }

    setCalculatedTasks(tmp);
  }, [startingNav]);

  const renderBodyRow = (data) => ({
    key: data.id,
    cells: [
      {
        key: "date",
        content: (
          <>
            {data.status === "RUNNING" && (
              <Label horizontal color={tsc("RUNNING")}>
                {" "}
                <Icon loading name="spinner" /> RUNNING{" "}
              </Label>
            )}{" "}
            {data?.results?.raw_data?.date}
          </>
        ),
      },
      {
        key: "startingnav",
        content: data?.results?.trading?.calc_starting_nav
          ? numeral(data?.results?.trading?.calc_starting_nav).format("$0,0.00")
          : "",
      },
      {
        key: "endingnav",
        content: data?.results?.trading?.calc_ending_nav
          ? numeral(data?.results?.trading?.calc_ending_nav).format("$0,0.00")
          : "",
      },
      {
        key: "dayprofit",
        content: data?.results?.trading?.calc_day_profit
          ? numeral(data?.results?.trading?.calc_day_profit).format("$0,0.00")
          : "",
      },
      {
        key: "percentchange",
        content: data?.results?.trading?.calc_percent_change
          ? numeral(data?.results?.trading?.calc_percent_change).format("0.00%")
          : "",
      },
      {
        key: "combokey",
        content: data?.results?.strategy_combo_key ?? "PENDING",
      },
      { key: "lastupdate", content: moment(data.updatedAt).format("LTS L") },
    ],
  });

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-status" }}
        title={`Forward Test | Levitas Capital`}
      />
      {!tasks ? (
        <Loader active inline="centered" size="massive" inverted />
      ) : (
        <Container>
          <Header as={"h1"} inverted>
            Forward Test
          </Header>
          <Input
            placeholder="Enter a Starting NAV"
            onChange={(event) => setStartingNav(Number(event.target.value))}
            type="number"
            fluid
          />
          <Table
            displayname="forwardtest"
            celled
            size="small"
            selectable
            striped
            compact="very"
            definition
            unstackable
            renderBodyRow={renderBodyRow}
            headerRow={[
              "Date",
              "Starting Nav",
              "Ending Nav",
              "Day PnL",
              "% Change",
              "Strategies",
              "Last Update",
            ]}
            tableData={
              calculatedTasks.length > 0 && startingNav >= 0
                ? calculatedTasks
                : tasks
            }
          />
        </Container>
      )}
    </>
  );
};
