import debug from "debug";

const BASE = "lc-control";

const COLOURS = {
  debug: "lightblue",
  info: "blue",
  warn: "pink",
  error: "red",
};

class Log {
  generateMessage(level, message, source, caller) {
    const namespace = `${BASE}:${level}:${caller}`;
    const createDebug = debug(namespace);

    createDebug.color = COLOURS[level];

    if (source) {
      createDebug(message, source);
    } else {
      createDebug(message);
    }
  }

  debug(message, source) {
    return this.generateMessage(
      "debug",
      message,
      source,
      new Error()?.stack?.split("\n")[2].trim().split(" ")[1] ?? ""
    );
  }

  info(message, source) {
    return this.generateMessage(
      "info",
      message,
      source,
      new Error()?.stack?.split("\n")[2].trim().split(" ")[1] ?? ""
    );
  }

  warn(message, source) {
    return this.generateMessage(
      "warn",
      message,
      source,
      new Error()?.stack?.split("\n")[2].trim().split(" ")[1] ?? ""
    );
  }

  error(message, source) {
    return this.generateMessage(
      "error",
      message,
      source,
      new Error()?.stack?.split("\n")[2].trim().split(" ")[1] ?? ""
    );
  }
}

export default new Log();
