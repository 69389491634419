import React, { useContext } from "react";
import { StatusContext } from "../contexts/StatusContext";
import {
  Popup,
  Icon,
  Sidebar,
  Grid,
  Responsive,
  Container,
  Dropdown,
  Menu,
} from "semantic-ui-react";
import Log from "../tools/Log";
import moment from "moment";

const StatusBar = () => {
  const statusChecks = useContext(StatusContext);
  //Log.debug("statusChecks", statusChecks);

  return (
    <Responsive
      as={Menu}
      icon
      inverted
      fixed="bottom"
      size="small"
      className="component-status-bar"
      minWidth={Responsive.onlyTablet.minWidth}
    >
      <Menu.Menu position="right">
        {statusChecks ? (
          <>
            {statusChecks.map((sc) => (
              <Menu.Item key={sc.name} fitted="vertically">
                <Popup
                  trigger={
                    <Icon
                      name="circle"
                      size="small"
                      loading={sc.isLoading}
                      color={
                        sc.isLoading
                          ? "yellow"
                          : sc.status === "UP"
                          ? "green"
                          : "red"
                      }
                    />
                  }
                  inverted
                  size="mini"
                  header={sc.name}
                  content={moment(sc.timestamp).format("LTS")}
                  position="top right"
                />
              </Menu.Item>
            ))}
          </>
        ) : (
          <Menu.Item name="circle">
            <Icon name="circle" disabled />
          </Menu.Item>
        )}
      </Menu.Menu>
    </Responsive>
  );
};

export default StatusBar;
