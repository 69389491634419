///<reference types="webpack-env" />
import React from "react";
import { render } from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import { Provider as ReduxProvider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import WebSocketProvider from "./contexts/WebSocketContext";
import StatusProvider from "./contexts/StatusContext";
import store, { persistor, history } from "./store";
import App from "./App";
import "semantic-ui-less/semantic.less";


render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ConnectedRouter history={history}>
        <Router>
          <WebSocketProvider>
            <StatusProvider>
              <App />
            </StatusProvider>
          </WebSocketProvider>
        </Router>
      </ConnectedRouter>
    </PersistGate>
  </ReduxProvider>,
  document.getElementById("app")
);

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./App", () => render);
  }
  localStorage.setItem("debug", process.env.DEBUG ?? "");
}
