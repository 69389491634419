import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, Slide, toast } from "react-toastify";

import { RootState } from "../rootReducer";

import Log from "../tools/Log";

const ErrorHandler = () => {
  const showError = (error) => {
    Log.debug("error", error);
    if (error && error.data && error.data.message) {
      toast.error(`${error.data.error.message}`);
      //toast.error(`${error.data.error.statusCode} - ${err.data.error.message}`);
    } else if (error) {
      toast.error(error);
    } else {
      toast.error("An error occured");
    }
  };

  // MARKETS
  const { error: marketsError } = useSelector(
    (state: RootState) => state.users
  );
  useEffect(() => {
    if (marketsError) {
      showError(marketsError.data);
    }
  }, [marketsError]);

  // USERS
  const { error: usersError } = useSelector((state: RootState) => state.users);
  useEffect(() => {
    if (usersError) {
      showError(usersError.data);
    }
  }, [usersError]);

  //TASKS
  const { error: tasksError } = useSelector((state: RootState) => state.tasks);
  useEffect(() => {
    if (tasksError) {
      showError(tasksError.data);
    }
  }, [tasksError]);

  //TESTS
  const { error: testsError } = useSelector((state: RootState) => state.tests);
  useEffect(() => {
    if (testsError) {
      showError(testsError.data);
    }
  }, [testsError]);

  //STATUSES
  //TODO this one is different
  //  const { error: statusesError } = useSelector((state: RootState) => state.statuses);
  //  useEffect(() => {
  //    if (statusesError) {
  //      showError(statusesError.data);
  //    }
  //  }, [statusesError]);

  return (
    <ToastContainer
      position="bottom-center"
      transition={Slide}
      autoClose={5000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
    />
  );
};

export default ErrorHandler;
