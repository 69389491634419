import React, { useState } from "react";
import { Header, Form, Button, Divider } from "semantic-ui-react";

import { Formik } from "formik";
import { FormField } from "../FormField";

import * as Yup from "yup";

interface Props {
  title: string;
  onSubmit: (any) => void;
  submitting: boolean;
}

export default function RegisterForm({
  title = "Register",
  onSubmit,
  submitting,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const validation = Yup.object().shape({
    id: Yup.string().label("Username").trim().required(),
    password: Yup.string().label("Password").trim().required(),
    //      .matches(
    //        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    //        "Minimum eight characters, at least one letter, one number and one special character"
    //      ),
    token: Yup.string().label("Registration Token").max(64).trim().required(),
  });

  return (
    <>
      <Header as="h1" inverted textAlign="center">
        {title}
      </Header>
      <Formik
        initialValues={{}}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit} autoComplete="off">
            <FormField
              id="id"
              name="id"
              component={Form.Input}
              placeholder="Username"
            />
            <FormField
              id="password"
              name="password"
              component={Form.Input}
              type={showPassword ? "text" : "password"}
              autoComplete="off"
              placeholder="Password"
              action={{
                icon: showPassword ? "eye slash" : "eye",
                onClick: () => setShowPassword(!showPassword),
                type: "button",
              }}
            />
            <Divider hidden />
            <FormField
              id="token"
              name="token"
              component={Form.Input}
              placeholder="Registration Token"
            />
            <Button primary type="submit" fluid loading={submitting}>
              Create Account
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}
