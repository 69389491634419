import axios from "axios";
import * as parseCookie from "./parseCookie";
const baseUrl = process.env.API_URL;

export interface User {
  username: string;
  password?: string;
  token?: string;
}

export async function getSelf() {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/users/me`;

  const { data } = await axios.get<User>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function createUser(user: User) {
  const url = `${baseUrl}/users/`;

  const { data } = await axios.post<any>(url, user, {
    headers: {},
  });

  return data;
}

export async function updateUser(user: any) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/users/${user.id}`;

  const { data } = await axios.put<User>(url, user, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export const requsetPassword = (email) => {
  return axios.post(`${baseUrl}/users/request-password`, email, {
    headers: {},
  });
};

export const resetPassword = (token) => {
  return axios.post(`${baseUrl}/users/reset-password`, token, {
    headers: {},
  });
};
