import React, { createContext } from "react";
import io from "socket.io-client";
//import { WS_BASE } from './config';
import { useDispatch } from "react-redux";

import { updateData } from "../slices/marketsSlice";
import { updateTaskData, updateTaskDataStatus } from "../slices/tasksSlice";

import Log from "../tools/Log";

const baseUrl = process.env.API_URL;

const WebSocketContext = createContext(null);

export { WebSocketContext };

const WebSocketProvider = ({ children }: { children: any }) => {
  let socket;
  let ws;

  const dispatch = useDispatch();

  if (!socket) {
    const url = `${baseUrl}/data/1`;
    socket = io.connect(url);

    socket.on("data message", (msg) => {
      console.log(msg);
    });
    socket.on("some room event", (msg) => {
      //     console.log("msg", msg);
      dispatch(updateData(msg));
    });

    socket.on("task update", (msg) => {
      Log.debug("task update msg", msg);
      dispatch(updateTaskData(msg));
    });

    socket.on("task status", (msg) => {
      Log.debug("task status msg", msg);
      dispatch(updateTaskDataStatus(msg));
    });

    ws = {
      socket: socket,
    };
  }

  return (
    <WebSocketContext.Provider value={ws}>{children}</WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
