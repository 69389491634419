import React, { useState } from "react";
import { resetPassword } from "../api/usersApi";
import ResetCredentialsForm from "../components/forms/ResetCredentialsForm";

import {
  Icon,
  Button,
  Container,
  Divider,
  Transition,
} from "semantic-ui-react";
import { Link, Redirect, useLocation } from "react-router-dom";

import Log from "../tools/Log";

export const ResetCredentials = () => {
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);

  let query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  const handleSubmit = (data) => {
    data.token = token;
    Log.debug("data", data);
    setSubmitting(true);
    resetPassword(data);
    setTimeout(() => {
      setSubmitting(false);
      setSuccess(true);
    }, 1000);
  };

  Log.debug("token", token);

  if (token === null) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Transition transitionOnMount>
        <Container className="mobile-container">
          {!success && (
            <ResetCredentialsForm
              submitting={submitting}
              onSubmit={handleSubmit}
            />
          )}
          {success && (
            <Container textAlign="center">
              <Divider hidden />
              <Icon
                style={{ fontSize: "6rem", color: "#BCD85F" }}
                name="check circle outline"
              />
              <Button as={Link} to="/" secondary>
                Proceed to Log In
              </Button>
            </Container>
          )}
        </Container>
      </Transition>
    </>
  );
};
