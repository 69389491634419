import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Grid, Menu, Transition } from "semantic-ui-react";
import { getToken } from "../api/authApi";

import LoginForm from "../components/forms/LoginForm";

import Log from "../tools/Log";
import { ToastError } from "../tools/ToastMessage";

export const Login = () => {
  const [submitting, setSubmitting] = useState(false);
  const [credentialError, setCredentialError] = useState(false);

  const history = useHistory();

  async function onSubmit(data) {
    setSubmitting(true);
    Log.debug("login", "onSubmit");
    try {
      await getToken(data.username, data.password);
      setSubmitting(false);
      history.push("/optimization");
    } catch (e) {
      setSubmitting(false);
      if (e.response.status === 401) {
        setCredentialError(true);
      } else {
        ToastError(e.response);
      }
    }
  }
  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-out pages-login" }}
        title={`Login | Levitas Capital`}
      />
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Transition transitionOnMount>
          <Grid.Column style={{ maxWidth: 450, margin: 20 }}>
            <LoginForm
              onSubmit={onSubmit}
              submitting={submitting}
              credentialError={credentialError}
            />
            <Menu inverted fixed="bottom" borderless>
              <Menu.Menu position="right">
                <Menu.Item
                  as={Link}
                  to="/register"
                  name="register"
                  content="Sign Up"
                />
                {/*
                <Menu.Item
                  as={Link}
                  to="/forgot"
                  name="forgot"
                  content="Forgot"
                />
*/}
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Transition>
      </Grid>
    </>
  );
};
