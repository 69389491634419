import React, { useEffect, useRef } from "react";

import { Item, Table, Header } from "semantic-ui-react";
import Tree from "react-d3-tree";

import { Task } from "../../api/tasksApi";

export const VisualizationTab = ({ task }: { task: Task }) => {
  return (
    <>
      <Item>
        <Header as={"h3"} content="Visualization" />
      </Item>
      <Tree
        data={task.visualization}
        initialDepth={1}
        separation={{
          siblings: 0.5,
          nonSiblings: 2,
        }}
        translate={{
          x: 20,
          y: 500,
        }}
        pathFunc="step"
      />
    </>
  );
};
