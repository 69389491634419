import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  Test,
  getTests,
  getTestById,
  createTest,
  updateTest,
  deleteTest,
} from "../api/testsApi";

import { AppThunk } from "../store";

interface TestState {
  testsById: Record<string, Test>;
  isLoading: boolean;
  isSubmitting: boolean;
  isInitialized: boolean;
  isEmpty: boolean;
  error: {
    timestamp: number;
    data: string;
  } | null;
}

const initialState: TestState = {
  testsById: {},
  isLoading: false,
  isSubmitting: false,
  isInitialized: false,
  isEmpty: true,
  error: null,
};

function startLoading(state: TestState) {
  state.isLoading = true;
  state.error = null;
}

function startSubmitting(state: TestState) {
  state.isSubmitting = true;
  state.error = null;
}

function loadingFailed(state: TestState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.isSubmitting = false;
  state.error = { timestamp: new Date().getTime(), data: action.payload };
}

const tests = createSlice({
  name: "tests",
  initialState,
  reducers: {
    getTestStart: startLoading,
    getTestsListStart: startLoading,
    createTestStart: startSubmitting,
    updateTestStart: startSubmitting,
    deleteTestStart: startSubmitting,

    getTestSuccess: (state, { payload }: PayloadAction<Test>) => {
      const { id } = payload;

      state.testsById[id] = payload;
      state.isLoading = false;
      state.isEmpty = false;
      state.isInitialized = true;
      state.error = null;
    },
    getTestsListSuccess: (state, { payload }: PayloadAction<Test[]>) => {
      state.isLoading = false;
      state.error = null;
      state.isEmpty = false;
      state.isInitialized = true;

      payload.forEach((test) => {
        state.testsById[test.id] = test;
      });
    },
    createTestSuccess: (state, { payload }: PayloadAction<Test>) => {
      const { id } = payload;

      state.testsById[id] = payload;
      state.isSubmitting = false;
      state.isInitialized = true;
      state.isEmpty = false;
      state.error = null;
    },
    updateTestSuccess: (state, { payload }: PayloadAction<Test>) => {
      const { id } = payload;

      state.testsById[id] = payload;
      state.isSubmitting = false;
      state.isInitialized = true;
      state.isEmpty = false;
      state.error = null;
    },
    updateTestStatus: (state, { payload }: PayloadAction<any>) => {
      const { id, process_log } = payload;

      if (id in state.testsById) {
        state.testsById[id].process_log = process_log;
      }
    },

    deleteTestSuccess: (state, { payload }: PayloadAction<string>) => {
      state.isSubmitting = false;
      state.error = null;

      if (payload in state.testsById) {
        delete state.testsById[payload];
      }
    },

    getTestsListNoDataSuccess(state) {
      state.isEmpty = true;
      state.isLoading = false;
      state.isInitialized = true;
      state.error = null;
    },
    getTestFailure: loadingFailed,
    getTestsListFailure: loadingFailed,
    createTestFailure: loadingFailed,
    updateTestFailure: loadingFailed,
    deleteTestFailure: loadingFailed,
  },
});

export const {
  getTestStart,
  getTestsListStart,
  getTestSuccess,
  getTestsListSuccess,
  getTestsListNoDataSuccess,
  getTestFailure,
  getTestsListFailure,

  createTestStart,
  createTestSuccess,
  createTestFailure,

  updateTestStart,
  updateTestSuccess,
  updateTestStatus,
  updateTestFailure,

  deleteTestStart,
  deleteTestSuccess,
  deleteTestFailure,
} = tests.actions;

export default tests.reducer;

export const fetchTestsList = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getTestsListStart());
    const testsList = await getTests();
    if (testsList && testsList.length) {
      dispatch(getTestsListSuccess(testsList));
    } else {
      dispatch(getTestsListNoDataSuccess());
    }
  } catch (err) {
    dispatch(getTestsListFailure(err.toString()));
  }
};

export const fetchTestById = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(getTestStart());
    const test = await getTestById(id);
    dispatch(getTestSuccess(test));
  } catch (err) {
    dispatch(getTestFailure(err.toString()));
  }
};

export const insertTest = (test: any): AppThunk => async (dispatch) => {
  try {
    dispatch(createTestStart());
    const response = await createTest(test);
    dispatch(createTestSuccess(response));
  } catch (err) {
    dispatch(createTestFailure(err.toString()));
  }
};

export const updateTestById = (test: Test): AppThunk => async (dispatch) => {
  try {
    dispatch(updateTestStart());
    const response = await updateTest(test);
    dispatch(updateTestSuccess(response));
  } catch (err) {
    dispatch(updateTestFailure(err.toString()));
  }
};

export const deleteTestById = (id: string): AppThunk => async (dispatch) => {
  try {
    dispatch(deleteTestStart());
    await deleteTest(id);
    dispatch(deleteTestSuccess(id));
  } catch (err) {
    dispatch(deleteTestFailure(err.toString()));
  }
};

export const updateTestData = (update: Test): AppThunk => async (dispatch) => {
  try {
    dispatch(updateTestSuccess(update));
  } catch (err) {
    dispatch(updateTestFailure(err.toString()));
  }
};

export const updateTestDataStatus = (update: any): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(updateTestStatus(update));
  } catch (err) {
    dispatch(updateTestFailure(err.toString()));
  }
};
