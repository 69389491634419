import React from "react";

import { Item, Table, Header } from "semantic-ui-react";

import { Task } from "../../api/tasksApi";

export const RawTab = ({ task }: { task: Task }) => {
  return (
    <>
      <Item>
        <Header as={"h3"} content="Raw Values" inverted />
      </Item>
      <Table compact size="small" celled striped>
        <Table.Body>
          {Object.entries(task.results.raw_data).map(([key, value]) => (
            <Table.Row key={key}>
              <Table.Cell width={3}>{key}</Table.Cell>
              <Table.Cell>
                <>{value}</>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};
