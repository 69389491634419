import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";

import { StreamRequest, postStreamRequest } from "../api/marketsApi";

interface MarketsState {
  requestedTickers: Record<string, StreamRequest>;
  quoteData: Record<string, any>;
  isLoading: boolean;
  error: {
    timestamp: number;
    data: string;
  } | null;
}

const initialState: MarketsState = {
  requestedTickers: {},
  quoteData: {},
  isLoading: false,
  error: null,
};

function startRequest(state: MarketsState) {
  state.isLoading = true;
  state.error = null;
}

function failRequest(state: MarketsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = { timestamp: new Date().getTime(), data: action.payload };
}

const markets = createSlice({
  name: "markets",
  initialState,
  reducers: {
    createStreamRequestStart: startRequest,
    createStreamRequestSuccess: (
      state,
      { payload }: PayloadAction<StreamRequest>
    ) => {
      const { ticker } = payload;

      state.requestedTickers[ticker] = payload;
      state.isLoading = false;
      state.error = null;
    },
    createStreamRequestFail: failRequest,

    updateQuoteData: (state, { payload }: PayloadAction<any>) => {
      //      const { ticker } = payload;

      state.quoteData["dummy"] = payload;
    },
  },
});

export const {
  createStreamRequestStart,
  createStreamRequestSuccess,
  createStreamRequestFail,

  updateQuoteData,
} = markets.actions;

export const createStreamRequest = (
  requestType: string,
  ticker: string
): AppThunk => async (dispatch) => {
  try {
    dispatch(createStreamRequestStart);
    const streamRequestResult = await postStreamRequest({
      requestType,
      ticker,
    });
    if (streamRequestResult && streamRequestResult.data) {
      dispatch(createStreamRequestSuccess(streamRequestResult));
    } else {
      dispatch(createStreamRequestFail);
    }
  } catch (err) {
    dispatch(createStreamRequestFail(err.toString()));
  }
};

export const updateData = (update: any): AppThunk => async (dispatch) => {
  try {
    dispatch(updateQuoteData(update));
  } catch (err) {
    //failue ofs ome kine?
    //  dispatch(createStreamRequestFail(err.toString()));
  }
};

export default markets.reducer;
