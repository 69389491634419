import React, { useEffect, createContext } from "react";

import { useSelector, useDispatch } from "react-redux";
import { StatusCheck, checkStatus } from "../slices/statusSlice";

import { RootState } from "../rootReducer";

import Log from "../tools/Log";

const status_checks = JSON.parse(process.env.STATUS_CHECKS ?? "{}");

const StatusContext = createContext<StatusCheck[]>([]);
export { StatusContext };

const randomInterval = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const StatusProvider = ({ children }: { children: any }) => {
  const dispatch = useDispatch();
  //Log.debug("status_checks", status_checks);

  const { statusChecks } = useSelector((state: RootState) => state.statuses);

  const checkValues = Object.values(statusChecks ?? []);

  useEffect(() => {
    status_checks.forEach(function (entry) {
      dispatch(checkStatus(entry));
    });
    let interval = setInterval(() => {
      status_checks.forEach(function (entry) {
        dispatch(checkStatus(entry));
      });
    }, randomInterval(30000, 35000));
    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <StatusContext.Provider value={Object.values(checkValues)}>
      {children}
    </StatusContext.Provider>
  );
};

export default StatusProvider;
