import React, { useState, useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { Link, NavLink } from "react-router-dom";
import {
  Icon,
  Sidebar,
  Grid,
  Responsive,
  Container,
  Dropdown,
  Header as Head,
  Menu,
} from "semantic-ui-react";
import Avatar from "react-avatar";

const Header = ({
  sideBarVisible,
  setSideBarVisible,
}: {
  sideBarVisible: boolean;
  setSideBarVisible: any;
}) => {
  const userProfile = useContext(AuthContext);

  const trigger = (
    <Avatar name={`${userProfile.username}`} size="32px" round={true} />
  );

  const options = [
    {
      key: "logout",
      text: "Sign Out",
      icon: "sign out",
      as: Link,
      to: "/logout",
    },
  ];

  return (
    <Menu fluid inverted borderless className="component-header">
      <Responsive as={Menu.Item} maxWidth={Responsive.onlyMobile.maxWidth}>
        <Head as="h4">
          <Icon
            name="sidebar"
            link
            inverted
            onClick={() => setSideBarVisible(true)}
          />
        </Head>
      </Responsive>

      <Responsive as={Menu.Menu} minWidth={Responsive.onlyTablet.minWidth}>
        {/*
        <Menu.Item
          name="dashboard"
          as={NavLink}
          to="/dashboard"
          content="Dashboard"
          fitted="vertically"
        />
*/}
        <Menu.Item
          name="optimization"
          as={NavLink}
          to="/optimization"
          content="Optimization"
          fitted="vertically"
        />
        <Menu.Item
          name="forwardtest"
          as={NavLink}
          to="/forwardtest"
          content="Forwardtest"
          fitted="vertically"
        />
        <Menu.Item
          name="backtests"
          as={NavLink}
          to="/backtests"
          content="Backtests"
          fitted="vertically"
        />
        <Menu.Item
          name="allocations"
          as={NavLink}
          to="/allocations"
          content="Allocations"
          fitted="vertically"
        />
        <Menu.Item
          name="status"
          as={NavLink}
          to="/status"
          content="Status"
          fitted="vertically"
        />
      </Responsive>

      <Menu.Menu position="right">
        <Menu.Item>
          <Dropdown
            trigger={trigger}
            options={options}
            pointing="top right"
            className={"avatar-menu"}
          />
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  );
};

export default Header;
