import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";
import { requsetPassword } from "../api/usersApi";
import { Segment, List, Container, Transition } from "semantic-ui-react";
import ForgotCredentialsForm from "../components/forms/ForgotCredentialsForm";

import { toast } from "react-toastify";

import Log from "../tools/Log";
import { ToastError } from "../tools/ToastMessage";

export const ForgotCredentials = () => {
  const [submitting, setSubmitting] = useState(false);

  let history = useHistory();

  async function handleSubmit(data) {
    setSubmitting(true);
    Log.debug("data", data);
    try {
      //const token = await requsetPassword(data);
      //      sendEmail({
      //        to: data.email,
      //        subject: "Password Reset",
      //        content: token.data,
      //        returnUrl: window.location.href.split("#")[0],
      //        templateId: "resetPassword",
      //      });
      toast.success("If an account exists, you will receive an email.");
      history.push("/");
    } catch (e) {
      setSubmitting(false);
      ToastError(e.response);
      Log.error("error", e);
    }
  }

  return (
    <>
      <Helmet>
        <title>Recover Password | Levitas Capital</title>
      </Helmet>
      <Transition transitionOnMount>
        <Container className="mobile-container">
          <ForgotCredentialsForm
            title={"Recover Password"}
            onSubmit={handleSubmit}
            submitting={submitting}
          />

          <Segment basic textAlign="center">
            <List>
              <List.Item>
                <Link to="/">Back to sign in</Link>
              </List.Item>
            </List>
          </Segment>
        </Container>
      </Transition>
    </>
  );
};
