import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";

import { RootState } from "../rootReducer";
import { fetchTestById } from "../slices/testsSlice";
import {
  Label,
  Item,
  Responsive,
  Input,
  Divider,
  List,
  Table,
  Image,
  Grid,
  Container,
  Accordion,
  Loader,
  Transition,
  Header,
  Icon,
  Menu,
  Segment,
  Button,
  Breadcrumb,
} from "semantic-ui-react";

import {
  TaskTypeColors as ttc,
  TaskStatusColors as tsc,
} from "../tools/labelColors";

import moment from "moment";

export const BacktestDetailsPage = () => {
  const dispatch = useDispatch();
  const { testId } = useParams();

  const [endingNav, setEndingNav] = useState(0);
  const { isLoading, isInitialized } = useSelector(
    (state: RootState) => state.tests
  );

  const test = useSelector((state: RootState) => state.tests.testsById[testId]);

  useEffect(() => {
    if (!test && testId) dispatch(fetchTestById(testId));
  }, [dispatch, testId, test]);

  const allocCalc = (en, ca, a) => {
    const value = Math.floor((en / ca) * a);
    return value === 0 ? "" : value;
  };

  const renderBodyRow = ({ key, ...props }, i) => ({
    key: `row-${i}`,
    cells: Object.values(props),
  });

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-optimization-detail" }}
        title={`Optimization Detail | Levitas Capital`}
      />
      {!test ? (
        <Loader active inline="centered" size="massive" inverted />
      ) : (
        <>
          <Container>
            <Header as={"h1"} inverted>
              Backtest Details
              <Label horizontal color={tsc(test.status)}>
                {test.status === "RUNNING" && <Icon loading name="spinner" />}
                {test.status}
              </Label>
              {test.synchronize && <Label color="yellow" content="SYNC" />}
            </Header>
            <Breadcrumb>
              <Breadcrumb.Section link as={Link} to={"/backtests"}>
                Backtests
              </Breadcrumb.Section>
              <Breadcrumb.Divider />
              <Breadcrumb.Section active>{testId}</Breadcrumb.Section>
            </Breadcrumb>
            <Segment inverted size="mini">
              <List>
                <List.Item>
                  <List.Icon name="chevron right" />
                  <List.Content>
                    {"Created At: "}
                    {moment(test.createdAt).format("LTS L")} (
                    {moment(test.createdAt).fromNow()})
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="chevron right" />
                  <List.Content>
                    {"Updated At: "}
                    {moment(test.updatedAt).format("LTS L")} (
                    {moment(test.updatedAt).fromNow()})
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Icon name="chevron right" />
                  <List.Content>Configuration: </List.Content>
                  <List.List>
                    <List.Item
                      icon="angle double right"
                      content={`Start Date: ${test.configuration.startDate}`}
                    />
                    <List.Item
                      icon="angle double right"
                      content={`Drawdown Limit: ${test.configuration.ddRestriction}%`}
                    />
                    <List.Item
                      icon="angle double right"
                      content={`Drawdown Duration Limit: ${test.configuration.ddDuration}`}
                    />
                    <List.Item
                      icon="angle double right"
                      content={`Single Day Drawdown Limit: ${test.configuration.ddSingleLimit}`}
                    />
                    <List.Item
                      icon="angle double right"
                      content={`Optimization Generations: ${test.configuration.optGenerations}`}
                    />
                  </List.List>
                </List.Item>
                {test.batchJobDetails && (
                  <List.Item>
                    <List.Icon name="chevron right" />
                    <List.Content>AWS Batch Job Details: </List.Content>
                    <List.List>
                      <List.Item
                        icon="angle double right"
                        content={`Job ARN: ${test.batchJobDetails.jobArn}`}
                      />
                      <List.Item>
                        <List.Icon name="angle double right" />
                        <List.Content>
                          Job Id:{" "}
                          <a
                            href={`https://console.aws.amazon.com/batch/v2/home#jobs/detail/${test.batchJobDetails.jobId}`}
                            target="_blank"
                          >
                            {test.batchJobDetails.jobId}
                          </a>
                        </List.Content>
                      </List.Item>
                      <List.Item
                        icon="angle double right"
                        content={`Job Name: ${test.batchJobDetails.jobName}`}
                      />
                    </List.List>
                  </List.Item>
                )}
              </List>
            </Segment>

            <Divider />

            <Item>
              <Header
                as={"h3"}
                content={`${test.results ? "" : "No"} History`}
                inverted
              />
            </Item>
            <Divider hidden />
          </Container>

          {test.results && (
            <>
              <Item>
                <Header as={"h3"} content="Starting Parameters" inverted />
              </Item>
              <Table
                celled
                size="small"
                selectable
                striped
                fluid
                compact="very"
                definition
                unstackable
                headerRow={test.parameters.header}
                renderBodyRow={renderBodyRow}
                tableData={test.parameters.data}
                style={{ overflowX: "scroll", display: "block" }}
              />

              <Divider />
              <Container fluid>
                <Table
                  celled
                  size="small"
                  selectable
                  striped
                  compact="very"
                  definition
                  unstackable
                  renderBodyRow={renderBodyRow}
                  tableData={test.results}
                  style={{ overflowX: "scroll", display: "block" }}
                />
              </Container>
            </>
          )}
        </>
      )}
    </>
  );
};
