import axios from "axios";
import * as parseCookie from "./parseCookie";
const baseUrl = process.env.API_URL;

export interface Test {
  id: string;
  synchronize: boolean;
  testType: string;
  status: string;
  configuration?: any;
  parameters?: any;
  batchJobDetails?: any;
  results?: any;
  createdAt: string;
  updatedAt: string;

  process_log?: string;
}

export async function getTests(): Promise<Test[]> {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tests`;

  const { data } = await axios.get<Test[]>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function getTestById(testId: string) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tests/${testId}`;

  const { data } = await axios.get<Test>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function createTest(test: any) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tests/`;

  const { data } = await axios.post<any>(url, test, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function updateTest(test: Test) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tests/${test.id}`;

  const { data } = await axios.put<Test>(url, test, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function deleteTest(testId: string) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/tests/${testId}`;

  const { data } = await axios.delete<void>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
