import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../store";

import { StatusCheckTarget, statusCheck } from "../api/statusChecks";

import moment from "moment";

interface StatusCheck {
  name: string;
  status: "UP" | "DOWN" | "OFF";
  url: string;
  timestamp: number;
  isLoading: boolean;
  error: string | null;
}
export type { StatusCheck };

interface StatusChecks {
  statusChecks: Record<string, StatusCheck>;
}

const initialState: StatusChecks = {
  statusChecks: {},
};

function startRequest(state: StatusChecks, action: PayloadAction<string>) {
  const name = action.payload;
  if (name in state.statusChecks) state.statusChecks[name].isLoading = true;
}

function failRequest(state: StatusChecks, action: PayloadAction<any>) {
  const { name, url, error } = action.payload;
  if (name in state.statusChecks) {
    state.statusChecks[name].isLoading = false;
    state.statusChecks[name].status = "DOWN";
    state.statusChecks[name].error = error;
  } else {
    state.statusChecks[name] = {
      name: name,
      status: "DOWN",
      url: url,
      timestamp: Date.now(),
      isLoading: false,
      error,
    };
  }
}

const status_checks = createSlice({
  name: "status_checks",
  initialState,
  reducers: {
    statusCheckStart: startRequest,
    statusCheckSuccess: (state, { payload }: PayloadAction<any>) => {
      const { target, response } = payload;

      const status =
        response.status === "UP" || response.status === "success"
          ? "UP"
          : "DOWN";

      state.statusChecks[target.name] = {
        name: target.name,
        status,
        url: target.url,
        timestamp: Date.now(),
        isLoading: false,
        error: null,
      };
    },
    statusCheckFail: failRequest,
  },
});

export const {
  statusCheckStart,
  statusCheckSuccess,
  statusCheckFail,
} = status_checks.actions;

export const checkStatus = (target: StatusCheckTarget): AppThunk => async (
  dispatch
) => {
  try {
    dispatch(statusCheckStart(target.name));

    const statusCheckResult = await statusCheck(target);

    if (statusCheckResult) {
      dispatch(
        statusCheckSuccess({ target: target, response: statusCheckResult })
      );
    } else {
      dispatch(statusCheckFail({ name: target.name, error: null }));
    }
  } catch (err) {
    dispatch(statusCheckFail({ name: target.name, error: err.toString() }));
  }
};

export default status_checks.reducer;
