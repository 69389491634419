import React, { useContext } from "react";
import { StatusContext } from "../contexts/StatusContext";
//import { useSelector, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import moment from "moment";

import {
  Menu,
  Header,
  Transition,
  Icon,
  Card,
  Container,
  Loader,
  Divider,
} from "semantic-ui-react";

export const StatusPage = () => {
  const statusChecks = useContext(StatusContext);

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-status" }}
        title={`Status | Levitas Capital`}
      />
      <Transition>
        <Container text>
          <Menu inverted borderless>
            <Menu.Item>
              <Header as={"h1"} content="System Status" inverted />
            </Menu.Item>
          </Menu>
          <Card.Group centered stackable itemsPerRow={2}>
            {statusChecks ? (
              <>
                {statusChecks.map((sc) => (
                  <Card key={sc.name}>
                    <Card.Content>
                      <Card.Header>{sc.name}</Card.Header>
                    </Card.Content>
                    <Card.Content extra>
                      <Icon
                        name="circle"
                        loading={sc.isLoading}
                        color={sc.status === "UP" ? "green" : "red"}
                      />
                      {moment(sc.timestamp).format("LTS")}
                    </Card.Content>
                  </Card>
                ))}
              </>
            ) : (
              <Loader active inline="centered" size="massive" inverted />
            )}
          </Card.Group>
        </Container>
      </Transition>
    </>
  );
};
