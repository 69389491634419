import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getAllocations, getAllocations2 } from "../api/staticsApi";

import { AppThunk } from "../store";

interface StaticsState {
  allocations: any;
  allocations2: any;
  isLoading: boolean;
  error: {
    timestamp: number;
    data: string;
  } | null;
}

const initialState: StaticsState = {
  allocations: null,
  allocations2: null,
  isLoading: false,
  error: null,
};

function startLoading(state: StaticsState) {
  state.isLoading = true;
  state.error = null;
}

function startSubmitting(state: StaticsState) {
  state.error = null;
}

function loadingFailed(state: StaticsState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.error = { timestamp: new Date().getTime(), data: action.payload };
}

const statics = createSlice({
  name: "statics",
  initialState,
  reducers: {
    fetchAllocationsStart: startLoading,
    fetchAllocationsSuccess: (state, { payload }: PayloadAction<any>) => {
      state.allocations = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllocations2Success: (state, { payload }: PayloadAction<any>) => {
      state.allocations2 = payload;
      state.isLoading = false;
      state.error = null;
    },
    fetchAllocationsFailure: loadingFailed,
  },
});

export const {
  fetchAllocationsStart,
  fetchAllocationsSuccess,
  fetchAllocations2Success,
  fetchAllocationsFailure,
} = statics.actions;

export default statics.reducer;

export const fetchAllocations = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchAllocationsStart());
    const data = await getAllocations();
    dispatch(fetchAllocationsSuccess(data));
  } catch (err) {
    dispatch(fetchAllocationsFailure(err.toString()));
  }
};

export const fetchAllocations2 = (): AppThunk => async (dispatch) => {
  try {
    dispatch(fetchAllocationsStart());
    const data = await getAllocations2();
    dispatch(fetchAllocations2Success(data));
  } catch (err) {
    dispatch(fetchAllocationsFailure(err.toString()));
  }
};
