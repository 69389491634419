import axios from "axios";
import * as parseCookie from "./parseCookie";
const baseUrl = process.env.API_URL;

export async function getAllocations() {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/allocations`;

  const { data } = await axios.get<any>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}

export async function getAllocations2() {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/allocations2`;

  const { data } = await axios.get<any>(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
