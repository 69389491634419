import React, { useState } from "react";
//import { useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import RegisterForm from "../components/forms/RegisterForm";
import {
  Grid,
  Menu,
  Segment,
  List,
  Container,
  Transition,
} from "semantic-ui-react";

//import { RootState } from "../rootReducer";
import { toast } from "react-toastify";
import { ToastError } from "../tools/ToastMessage";

import { createUser } from "../api/usersApi";

import { Helmet } from "react-helmet";

import Log from "../tools/Log";

export const Register = () => {
  // const dispatch = useDispatch();
  const [submitting, setSubmitting] = useState(false);
  let history = useHistory();

  async function onSubmit(data) {
    setSubmitting(true);

    try {
      const resp = await createUser(data);
      Log.debug("resp", resp);

      toast.success(`Account created (${resp.id}).`);
      setSubmitting(false);
      history.push("/");
    } catch (e) {
      setSubmitting(false);
      ToastError(e.response);
    }
  }

  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-out pages-register" }}
        title={`Register | Levitas Capital`}
      />
      <Grid
        textAlign="center"
        style={{ height: "100vh" }}
        verticalAlign="middle"
      >
        <Transition transitionOnMount>
          <Grid.Column style={{ maxWidth: 450, margin: 20 }}>
            <RegisterForm
              title={"Create an Account"}
              onSubmit={onSubmit}
              submitting={submitting}
            />
            <Menu inverted fixed="bottom" borderless>
              <Menu.Menu position="right">
                <Menu.Item as={Link} to="/" name="index" content="Sign In" />
              </Menu.Menu>
            </Menu>
          </Grid.Column>
        </Transition>
      </Grid>
    </>
  );
};
