export const getToken = (cookieItem) => {
  var name = cookieItem + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArr = decodedCookie.split(";");
  for (var i = 0; i < cookieArr.length; i++) {
    var item = cookieArr[i];
    while (item.charAt(0) == " ") {
      item = item.substring(1);
    }
    if (item.indexOf(name) == 0) {
      return item.substring(name.length, item.length);
    }
  }
  return "";
};
