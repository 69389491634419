const taskTypeColors: {
  [key: string]:
    | "teal"
    | "red"
    | "orange"
    | "yellow"
    | "olive"
    | "green"
    | "blue"
    | "violet"
    | "purple"
    | "pink"
    | "brown"
    | "grey"
    | "black"
    | undefined;
} = {
  CALCULATE: "orange",
  OPTIMIZE: "pink",
  BACKTEST: "teal",
  FORWARDTEST: "yellow",
};

export const TaskTypeColors = (value: string) => taskTypeColors[value];

const taskStatusColors: {
  [key: string]:
    | "teal"
    | "red"
    | "orange"
    | "yellow"
    | "olive"
    | "green"
    | "blue"
    | "violet"
    | "purple"
    | "pink"
    | "brown"
    | "grey"
    | "black"
    | undefined;
} = {
  SUBMITTED: "olive",
  PENDING: "violet",
  RUNNING: "blue",
  IDLE: "teal",
  COMPLETE: "green",
  ERROR: "red",
};

export const TaskStatusColors = (value: string) => taskStatusColors[value];
