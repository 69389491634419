import axios from "axios";
import * as parseCookie from "./parseCookie";
const baseUrl = process.env.API_URL;

export interface StreamRequest {
  requestType: string;
  ticker: string;
}

export async function postStreamRequest(request: StreamRequest) {
  const token = parseCookie.getToken("__session");
  const url = `${baseUrl}/markets/streams`;

  const { data } = await axios.post(url, request, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return data;
}
