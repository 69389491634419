import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Icon, Sidebar, Menu } from "semantic-ui-react";
import { StatusContext } from "../contexts/StatusContext";

const NavSideBar = ({
  sideBarVisible,
  setSideBarVisible,
}: {
  sideBarVisible: boolean;
  setSideBarVisible: any;
}) => {
  const statusChecks = useContext(StatusContext);
  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      direction="left"
      onHide={() => setSideBarVisible(false)}
      inverted
      vertical
      visible={sideBarVisible}
      className="component-sidebar"
    >
      <Menu.Item>
        <Menu.Header>Navigation</Menu.Header>
      </Menu.Item>
      {/*
      <Menu.Item
        name="dashboard"
        as={NavLink}
        to="/dashboard"
        content="Dashboard"
        onClick={() => setSideBarVisible(false)}
      />
*/}
      <Menu.Item
        name="optimization"
        as={NavLink}
        to="/optimization"
        content="Optimization"
        onClick={() => setSideBarVisible(false)}
      />
      <Menu.Item
        name="forwardtest"
        as={NavLink}
        to="/forwardtest"
        content="Forwardtest"
        onClick={() => setSideBarVisible(false)}
      />
      <Menu.Item
        name="backtests"
        as={NavLink}
        to="/backtests"
        content="Backtests"
        onClick={() => setSideBarVisible(false)}
      />
      <Menu.Item
        name="allocations"
        as={NavLink}
        to="/allocations"
        content="Allocations"
        onClick={() => setSideBarVisible(false)}
      />
      <Menu.Item
        name="status"
        as={NavLink}
        to="/status"
        content="Status"
        onClick={() => setSideBarVisible(false)}
      />
      <Menu.Item>
        <Grid>
          <Grid.Row>
            {statusChecks ? (
              <>
                {statusChecks.map((sc) => (
                  <Grid.Column key={sc.name}>
                    <Icon
                      name="circle"
                      size="small"
                      loading={sc.isLoading}
                      color={sc.status === "UP" ? "green" : "red"}
                    />
                  </Grid.Column>
                ))}
              </>
            ) : (
              <Grid.Column>
                <Icon name="circle" disabled />
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>
      </Menu.Item>
    </Sidebar>
  );
};

export default NavSideBar;
