import React from "react";
import { Helmet } from "react-helmet";

import { Responsive, Container, Image, Button, Card } from "semantic-ui-react";

import MarketsCard from "../components/cards/MarketsCard";

export const DashboardPage = () => {
  return (
    <>
      <Helmet
        bodyAttributes={{ class: "logged-in pages-dashboard" }}
        title={`Dashboard | Levitas Capital`}
      />
      <Container>
        <Responsive
          as={Card.Group}
          minWidth={Responsive.onlyTablet.minWidth}
          centered
          stackable
        >
          <MarketsCard />
        </Responsive>

        <Responsive
          as={Card.Group}
          maxWidth={Responsive.onlyMobile.maxWidth}
          centered
          stackable
          itemsPerRow={2}
        >
          <MarketsCard />
        </Responsive>

        <Card.Group centered stackable itemsPerRow={2}></Card.Group>
      </Container>
    </>
  );
};
