import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "../../rootReducer";
import { createStreamRequest } from "../../slices/marketsSlice";
import { Formik } from "formik";
import { FormField } from "../FormField";

import {
  Icon,
  Button,
  Select,
  Input,
  Segment,
  Divider,
  Container,
  Header,
  Card,
  Grid,
  List,
  Transition,
  Form,
  Loader,
  Modal,
} from "semantic-ui-react";

import Log from "../../tools/Log";

export const MarketRequest = () => {
  const dispatch = useDispatch();
  const [selectedTicker, setSelectedTicker]: any = useState(undefined);

  const { requestedTickers, isLoading } = useSelector(
    (state: RootState) => state.markets
  );

  const tickers = [
    { key: "1", value: "@ES#", text: "@ES#" },
    { key: "2", value: "VIX.XO", text: "VIX.XO" },
    { key: "3", value: "@VX#", text: "@VX#" },
  ];

  async function handleClick() {
    if (!("@ES#" in requestedTickers))
      dispatch(createStreamRequest("level_1", "@ES#"));
    Log.debug("data", selectedTicker);
  }

  return (
    <Button.Group>
      <Select
        clearable
        name="ticker"
        placeholder="Select a symbol"
        className="button icon"
        icon={false}
        onChange={(e, d) => setSelectedTicker(d.value)}
        options={tickers}
        action="Search"
      />
      <Button
        primary
        onClick={handleClick}
        disabled={!selectedTicker}
        loading={isLoading}
        icon="play"
      />
    </Button.Group>
  );
};

export default MarketRequest;
