/* eslint-disable import/no-named-as-default */
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Icon, Header as Head, Sidebar, Segment } from "semantic-ui-react";

import { AuthContext } from "./contexts/AuthContext";
import Log from "./tools/Log";
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { UpdateReset } from "./rootReducer";

import {
  Route,
  Switch,
  withRouter,
  Redirect,
  useRouteMatch,
} from "react-router-dom";

import Header from "./components/Header";
import NavSideBar from "./components/NavSideBar";
import StatusBar from "./components/StatusBar";
import ErrorHandler from "./components/ErrorHandler";

// Base Routes
import { Login } from "./pages/Login";
import { Register } from "./pages/Register";
import { ForgotCredentials } from "./pages/ForgotCredentials";
import { ResetCredentials } from "./pages/ResetCredentials";

// Logged In
import { DashboardPage } from "./pages/DashboardPage";

import { OptimizationPage } from "./pages/OptimizationPage";
import { OptimizationDetailsPage } from "./pages/OptimizationDetailsPage";

import { ForwardtestPage } from "./pages/ForwardtestPage";

import { BacktestPage } from "./pages/BacktestPage";
import { BacktestDetailsPage } from "./pages/BacktestDetailsPage";

import { AllocationsPage } from "./pages/AllocationsPage";

import { StatusPage } from "./pages/StatusPage";

import "react-toastify/dist/ReactToastify.min.css";

const App = withRouter(() => {
  const dispatch = useDispatch();

  const getSession = () => {
    const jwt = Cookies.get("__session");
    let session;
    try {
      if (jwt) {
        const base64Url = jwt.split(".")[1];
        const base64 = base64Url.replace("-", "+").replace("_", "/");
        session = JSON.parse(window.atob(base64));
      }
    } catch (error) {
      Log.debug("getSession", error);
    }
    return session;
  };

  const UnprotectedRoute = ({ comp: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props: any) =>
          getSession() ? (
            <Redirect
              to={{
                pathname: "/optimization",
                state: { from: props.location },
              }}
            />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };

  const ProtectedRoute = ({ comp: Component, roles, ...rest }) => {
    const session = getSession();
    Log.debug("session", session);
    if (session) {
      // roles
      //if (roles.some(r=>session.resource_access.account.roles.includes(r))) {
      return (
        <AuthContext.Provider value={session}>
          <Route {...rest} render={(props) => <Component {...props} />} />
        </AuthContext.Provider>
      );
    } else {
      return (
        <Route
          {...rest}
          render={(props: any) => (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )}
        />
      );
    }
  };

  const LogOut = () => {
    document.cookie = "__session= ; expires = Thu, 01 Jan 1970 00:00:00 GMT";
    dispatch(UpdateReset());

    return (
      <Route
        render={(props: any) => (
          <Redirect to={{ pathname: "/", state: { from: props.location } }} />
        )}
      />
    );
  };

  const LoggedOut = () => {
    return (
      <>
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/forgot" component={ForgotCredentials} />
          <Route path="/reset" component={ResetCredentials} />
          <Route path="/register" exact component={Register} />
        </Switch>
      </>
    );
  };

  const LoggedIn = () => {
    let match = useRouteMatch();
    return (
      <>
        <Sidebar.Pushable>
          <NavSideBar
            sideBarVisible={sideBarVisible}
            setSideBarVisible={setSideBarVisible}
          />
          <Sidebar.Pusher dimmed={sideBarVisible}>
            <Header
              sideBarVisible={sideBarVisible}
              setSideBarVisible={setSideBarVisible}
            />
            <Switch>
              <Route path="/dashboard" component={DashboardPage} />

              <Route path="/optimization" exact component={OptimizationPage} />
              <Route
                path="/optimization/:taskId"
                component={OptimizationDetailsPage}
              />

              <Route path="/forwardtest" component={ForwardtestPage} />

              <Route path="/backtests" exact component={BacktestPage} />
              <Route
                path="/backtests/:testId"
                component={BacktestDetailsPage}
              />
              <Route path="/allocations" exact component={AllocationsPage} />

              <Route path="/status" component={StatusPage} />
            </Switch>
          </Sidebar.Pusher>
        </Sidebar.Pushable>
        <StatusBar />
      </>
    );
  };

  const NotFound = () => {
    return (
      <>
        <Helmet>
          <title>404 | Levitas Capital</title>
        </Helmet>
        <Segment placeholder>
          <Head icon>
            <Icon name="question circle outline" color="red" />
            <Head.Subheader> 404 </Head.Subheader>
          </Head>
        </Segment>
      </>
    );
  };

  const [sideBarVisible, setSideBarVisible] = useState(false);
  return (
    <>
      <Switch>
        <UnprotectedRoute path="/" exact comp={LoggedOut} />
        <UnprotectedRoute path="/register" comp={LoggedOut} />
        <UnprotectedRoute path="/forgot" comp={LoggedOut} />
        <UnprotectedRoute path="/reset" comp={LoggedOut} />

        <ProtectedRoute path="/dashboard" comp={LoggedIn} roles={[]} />
        <ProtectedRoute path="/optimization" comp={LoggedIn} roles={[]} />
        <ProtectedRoute path="/forwardtest" comp={LoggedIn} roles={[]} />
        <ProtectedRoute path="/backtests" comp={LoggedIn} roles={[]} />
        <ProtectedRoute path="/allocations" comp={LoggedIn} roles={[]} />
        <ProtectedRoute path="/status" comp={LoggedIn} roles={[]} />

        <Route path="/logout" component={LogOut} />
        <Route component={NotFound} />
      </Switch>

      <ErrorHandler />
    </>
  );
});

export default App;
