import React, { useState } from "react";
import { Form, Button, Segment, Header } from "semantic-ui-react";

import { Formik } from "formik";
import { FormField } from "../FormField";

import * as Yup from "yup";

interface Props {
  title?: string;
  onSubmit: (any) => void;
  submitting: boolean;
}

export default function ResetCredentialsForm({
  title = "Reset Password",
  onSubmit,
  submitting = false,
}: Props) {
  const [showPassword, setShowPassword] = useState(false);

  const validation = Yup.object().shape({
    password: Yup.string().label("Password").trim().required(),
  });

  return (
    <>
      <Header as="h1" inverted textAlign="center">
        Reset Password
      </Header>
      <Formik
        initialValues={{ termsOfService: false }}
        validationSchema={validation}
        onSubmit={onSubmit}
      >
        {(props) => (
          <Form onSubmit={props.handleSubmit}>
            <Segment basic inverted>
              {" "}
              Use at least 8 characters. Don&#39;t use a password from another
              site, and don&#39;t include any personal information such as your
              name or date of birth.{" "}
            </Segment>
            <FormField
              name="password"
              component={Form.Input}
              label="Password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              action={{
                icon: showPassword ? "eye slash" : "eye",
                onClick: () => setShowPassword(!showPassword),
                type: "button",
              }}
            />

            <Button secondary type="submit" loading={submitting} fluid>
              CHANGE PASSWORD
            </Button>
          </Form>
        )}
      </Formik>
    </>
  );
}
