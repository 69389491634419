import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { RootState } from "../../rootReducer";
import { createStreamRequest } from "../../slices/marketsSlice";

import { Card, Grid, Icon, Table, Label, Segment } from "semantic-ui-react";
import { MarketRequest } from "../common/MarketRequest";

import NumberFormat from "react-number-format";

const MarketsCard = () => {
  const dispatch = useDispatch();

  const { requestedTickers, quoteData } = useSelector(
    (state: RootState) => state.markets
  );

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <Grid columns="equal">
            <Grid.Column>Markets</Grid.Column>
            <Grid.Column textAlign="right">
              <MarketRequest />
            </Grid.Column>
          </Grid>
        </Card.Header>
      </Card.Content>
      <Card.Content>
        <Table inverted unstackable attached>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>{quoteData["dummy"]}</Table.HeaderCell>
              <Table.HeaderCell>Bid</Table.HeaderCell>
              <Table.HeaderCell>Ask</Table.HeaderCell>
              <Table.HeaderCell>Last</Table.HeaderCell>
              <Table.HeaderCell>Close</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {/*
            <Table.Row key={subscription}>
            {subscriptions
              .filter((subscription) => subscription !== "INVITED")
              .map((subscription, index) => (
                <Table.Row key={subscription}>
                  <Table.Cell>{subscription}</Table.Cell>
                  <Table.Cell>
                    {subscription ? (
                      <NumberFormat
                        style={{ color: "green" }}
                        value={subscription}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    ) : (
                      `---`
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Label>
                      {subscription}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    <Icon name="ellipsis vertical"></Icon>
                  </Table.Cell>
                </Table.Row>
              ))}
*/}
          </Table.Body>
        </Table>
        <Segment padded="very" textAlign="center" inverted attached="bottom">
          <MarketRequest />
        </Segment>
      </Card.Content>
    </Card>
  );
};

export default MarketsCard;
