import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, getSelf, createUser, updateUser } from "../api/usersApi";

import { AppThunk } from "../store";

interface UsersState {
  totalCount: number;
  page: number;
  perPage: number;
  usersById: Record<string, User>;
  self: User | null;
  currentPageUsers: string[];
  isEmpty: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  error: {
    timestamp: number;
    data: string;
  } | null;
}

const initialState: UsersState = {
  page: 1,
  totalCount: 0,
  perPage: 10,
  currentPageUsers: [],
  usersById: {},
  self: null,
  isEmpty: true,
  isLoading: false,
  isSubmitting: false,
  error: null,
};

function startLoading(state: UsersState) {
  state.isLoading = true;
  state.error = null;
}

function startSubmitting(state: UsersState) {
  state.isSubmitting = true;
  state.error = null;
}

function loadingFailed(state: UsersState, action: PayloadAction<string>) {
  state.isLoading = false;
  state.isSubmitting = false;
  state.error = { timestamp: new Date().getTime(), data: action.payload };
}

const users = createSlice({
  name: "users",
  initialState,
  reducers: {
    getUserStart: startLoading,
    getUsersListStart: startLoading,
    createUserStart: startSubmitting,
    updateUserStart: startSubmitting,
    getUserSuccess: (state, { payload }: PayloadAction<User>) => {
      const { username } = payload;

      state.usersById[username] = payload;
      state.isLoading = false;
      state.error = null;
    },
    getSelfSuccess: (state, { payload }: PayloadAction<User>) => {
      state.self = payload;
      state.isLoading = false;
      state.error = null;
    },
    getUsersListNoDataSuccess(state) {
      state.isEmpty = true;
      state.isLoading = false;
      state.error = null;
    },
    updateUserSuccess: (state, { payload }: PayloadAction<any>) => {
      state.self = payload;
      state.isLoading = false;
      state.error = null;
    },
    createUserSuccess: (state, { payload }: PayloadAction<any>) => {
      const { id } = payload;

      state.usersById[id] = payload;
      state.isSubmitting = false;
      state.error = null;
    },
    getUserFailure: loadingFailed,
    getUsersListFailure: loadingFailed,
    createUserFailure: loadingFailed,
    updateUserFailure: loadingFailed,
  },
});

export const {
  getUserStart,
  getUsersListStart,
  getUserSuccess,

  getSelfSuccess,
  getUsersListNoDataSuccess,
  getUserFailure,
  getUsersListFailure,

  createUserStart,
  createUserSuccess,
  createUserFailure,

  updateUserStart,
  updateUserSuccess,
  updateUserFailure,
} = users.actions;

export default users.reducer;

export const fetchSelf = (): AppThunk => async (dispatch) => {
  try {
    dispatch(getUserStart());
    const user = await getSelf();
    dispatch(getSelfSuccess(user));
  } catch (err) {
    dispatch(getUserFailure(err.toString()));
  }
};

export const insertUser = (user: any): AppThunk => async (dispatch) => {
  try {
    dispatch(createUserStart());
    const response = await createUser(user);
    dispatch(createUserSuccess(response));
    return response;
  } catch (err) {
    dispatch(createUserFailure(err.toString()));
    return null;
  }
};

export const updateUserById = (user: any): AppThunk => async (dispatch) => {
  try {
    dispatch(updateUserStart());
    const response = await updateUser(user);
    dispatch(updateUserSuccess(response));
    return response;
  } catch (err) {
    dispatch(updateUserFailure(err.toString()));
    return null;
  }
};
