import { combineReducers } from "redux";
import { createBrowserHistory } from "history";
import { connectRouter } from "connected-react-router";

// Data reducers
import marketsReducer from "./slices/marketsSlice";
import statusReducer from "./slices/statusSlice";
import staticsReducer from "./slices/staticsSlice";
import tasksReducer from "./slices/tasksSlice";
import testsReducer from "./slices/testsSlice";
import usersReducer from "./slices/usersSlice";

// UI reducers
//
const history = createBrowserHistory();

const appReducer = combineReducers({
  router: connectRouter(history),
  markets: marketsReducer,
  statics: staticsReducer,
  statuses: statusReducer,
  tasks: tasksReducer,
  tests: testsReducer,
  users: usersReducer,
});

// Action
export const UpdateReset = () => {
  return {
    type: "Reset",
  };
};

const rootReducer = (state, action) => {
  if (action.type === "Reset") {
    state = undefined;
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
