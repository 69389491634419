import { configureStore, getDefaultMiddleware, Action } from "@reduxjs/toolkit";
import { ThunkAction } from "redux-thunk";
import { createHashHistory } from "history";
import { routerMiddleware } from "connected-react-router";

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer, { RootState } from "./rootReducer";

export const history = createHashHistory({
  hashType: "slash",
  getUserConfirmation: (message, callback) => callback(window.confirm(message)),
});

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [
  routerMiddleware(history),
  ...getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
];

if (process.env.NODE_ENV === `development`) {
  const { createLogger } = require(`redux-logger`);

  middleware.push(
    createLogger({
      predicate: (getState, action) =>
        ![
          "status_checks/statusCheckStart",
          "status_checks/statusCheckSuccess",
        ].includes(action.type),
    })
  );
}

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV === "development",
});

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept("./rootReducer", () => {
      const newRootReducer = require("./rootReducer").default;
      store.replaceReducer(newRootReducer);
    });
  }
}

export type AppDispatch = typeof store.dispatch;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const persistor = persistStore(store);

export default store;
